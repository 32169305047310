<template>
  <div class="main">
    <div class="fixed">
      <div class="tabs-nav">
        <div class="layout cl">
          <!--註冊须知-->
          <a class="item" :class="{active:current==='1'}" @click="current='1'"><span>{{$t('Join Policy')}}</span></a>
          <!--会员方案-->
          <!--<a class="item" :class="{active:current==='2'}" @click="current='2'"><span>{{$t('Affiliate Program')}}</span></a>-->
          <!--准备材料-->
          <a class="item" :class="{active:current==='3'}" @click="current='3'"><span>{{$t('Prepare materials')}}</span></a>
          <!--查询註冊-->
          <a class="item" :class="{active:current==='4'}" @click="current='4'"><span>{{$t('Check join')}}</span></a>
        </div>
      </div>
    </div>
    <div class="tabs-content" v-if="current==='1'">
      <div class="layout">
        <div class="h2">{{$t('Join Policy')}}</div>
        <div class="rich-text" v-html="$t('JoinPolicy')"></div>
      </div>
    </div>
    <div class="tabs-content" v-else-if="current==='2'&&false">
      <div class="layout">
        <div class="h2">{{$t('Star Merchant Package')}}</div>
        <div class="txt-c">
          <member-list :is-buy="false" style="display:inline-block;margin:0 -12px"/>
        </div>
      </div>
    </div>
    <div class="tabs-content" v-else-if="current==='3'">
      <div class="layout rich-text" v-html="$t('PrepareMaterials')"></div>
    </div>
    <div class="tabs-content" v-else-if="current==='4'">
      <div class="layout">
        <div class="h2">{{$t('contactUs')}}</div>
        <contact-us/>
      </div>
    </div>
  </div>
</template>
<script>
  import ContactUs from './components/ContactUs';
  import MemberList from '../admin/member/components/MemberList';

  export default {
    name: 'JoinusDetail',
    i18n: {
      messages: {
        'zh-cn': {
          'JoinPolicy': '<p>1. 「一游」（UNU Travel）旅游平台暂未授权任何机构进行代理招商服务，入驻申请流程及相关的收费说明均以「一游」（UNU Travel）旅游平台官方入驻页面为准。</p><p>2. 「一游」（UNU Travel）旅游平台有权根据包括但不限于产品、资源需求、公司经营状况、服务水准等其他因素退回商家入驻申请，同时有权在申请入驻及后续经营阶段要求商家提供其他资质。</p><p>3. 「一游」（UNU Travel）旅游平台将结合行业发展动态、国家及地区相关规定等，不定期更新入驻标准；若「一游」（UNU Travel）旅游平台修订入驻标准，则商家须符合当前最新的入驻标准。</p><p>4. 申请入驻商家必须如实提供资料和资讯，并确保申请入驻及后续经营阶段提供的相关资质和资讯的真实性、完整性、有效性，一旦发现虚假资质或资讯的，「一游」（UNU Travel）旅游平台将不再与商家进行合作并有权根据「一游」（UNU Travel）旅游平台规则及与商家签署的相关协议之约定进行处理。</p><p><br/></p><p>入驻过程中有任何建议和问题，可发送邮件至 bussiness@unutravel.com 查询。</p>',
          'PrepareMaterials': '<p>申请加入UNU Travel前，请先准备以下材料，以便一步填妥注册申请表</p><p><br/></p><div class="panel"><div class="hd">旅行社</div><div class="bd"><p>1. 营业执照</p><p>2. 经营许可证（牌照）</p></div></div>'
        },
        'zh-hk': {
          'JoinPolicy': '<p>1. 「一遊」（UNU Travel）旅遊平台暫未授權任何機構進行代理招商服務，入駐申請流程及相關的收費說明均以「一遊」（UNU Travel）旅遊平台官方入駐頁面為准。</p><p>2. 「一遊」（UNU Travel）旅遊平台有權根據包括但不限於產品、資源需求、公司經營狀況、服務水準等其他因素退回商家入駐申請，同時有權在申請入駐及後續經營階段要求商家提供其他資質。</p><p>3. 「一遊」（UNU Travel）旅遊平台將結合行業發展動態、國家及地區相關規定等，不定期更新入駐標準；若「一遊」（UNU Travel）旅遊平台修訂入駐標準，則商家須符合當前最新的入駐標準。</p><p>4. 申請入駐商家必須如實提供資料和資訊，並確保申請入駐及後續經營階段提供的相關資質和資訊的真實性、完整性、有效性，一旦發現虛假資質或資訊的，「一遊」（UNU Travel）旅遊平台將不再與商家進行合作並有權根據「一遊」（UNU Travel）旅遊平台規則及與商家簽署的相關協議之約定進行處理。</p><p><br/></p><p>入駐過程中有任何建議和問題，可發送郵件至 bussiness@unutravel.com 查詢。</p>',
          'PrepareMaterials': '<p>申請加入UNU Travel前，請先準備以下材料，以便一步填妥註冊申請表</p><p><br/></p><div class="panel"><div class="hd">旅行社</div><div class="bd"><p>1. 營業執照</p><p>2. 經營許可證（牌照）</p></div></div>'
        },
        'en': {
          'JoinPolicy': '<p>1. 「一遊」（UNU Travel）旅遊平台暫未授權任何機構進行代理招商服務，入駐申請流程及相關的收費說明均以「一遊」（UNU Travel）旅遊平台官方入駐頁面為准。</p><p>2. 「一遊」（UNU Travel）旅遊平台有權根據包括但不限於產品、資源需求、公司經營狀況、服務水準等其他因素退回商家入駐申請，同時有權在申請入駐及後續經營階段要求商家提供其他資質。</p><p>3. 「一遊」（UNU Travel）旅遊平台將結合行業發展動態、國家及地區相關規定等，不定期更新入駐標準；若「一遊」（UNU Travel）旅遊平台修訂入駐標準，則商家須符合當前最新的入駐標準。</p><p>4. 申請入駐商家必須如實提供資料和資訊，並確保申請入駐及後續經營階段提供的相關資質和資訊的真實性、完整性、有效性，一旦發現虛假資質或資訊的，「一遊」（UNU Travel）旅遊平台將不再與商家進行合作並有權根據「一遊」（UNU Travel）旅遊平台規則及與商家簽署的相關協議之約定進行處理。</p><p><br/></p><p>入駐過程中有任何建議和問題，可發送郵件至 bussiness@unutravel.com 查詢。</p>',
          'PrepareMaterials': '<p>申請加入UNU Travel前，請先準備以下材料，以便一步填妥註冊申請表</p><p><br/></p><div class="panel"><div class="hd">旅行社</div><div class="bd"><p>1. 營業執照</p><p>2. 經營許可證（牌照）</p></div></div>'
        }
      }
    },
    components: {MemberList, ContactUs},
    data() {
      return {
        current: ''
      };
    },
    mounted() {
      this.current = this.$route.query.current;
    }
  };
</script>
<style scoped lang="less">
  .main{
    margin-bottom:-30px;padding-bottom:30px;padding-top:60px;background-color:#f4f4f4;
    .fixed{position:fixed;top:60px;right:0;left:0;z-index:1000;background-color:#fff;}
    .tabs-nav{
      line-height:60px;border-bottom:1px solid #f4f4f4;
      .item{
        float:left;width:25%;text-align:center;color:#292c2d;font-size:24px;cursor:pointer;
        span{position:relative;display:inline-block;vertical-align:top;}
        &:hover{color:#ff6f61;}
        &.active{
          color:#ff6f61;
          span:after{position:absolute;right:0;bottom:0;left:0;height:4px;background-color:#ff6f61;content:'';}
        }
      }
    }
    /deep/ .panel{
      border:1px solid #f4f4f4;border-radius:4px;
      .hd{padding:0 24px;line-height:40px;color:#292c2d;font-size:16px;background-color:#fbfbfb;}
      .bd{padding:24px;}
    }
  }
  .tabs-content{
    padding-top:24px;
    .layout{padding:32px;background-color:#fff;}
    .h2{margin-bottom:24px;text-align:center;color:#292c2d;font-size:24px;}
  }
  .rich-text{
    /deep/ p{
      margin-bottom:10px;line-height:1.8;color:#505050;font-size:16px;
      &:last-child{margin-bottom:0;}
    }
  }
</style>
