<template>
  <div class="contact-us">
    <template v-if="!submitCompleted">
      <div class="form-group">
        <!--姓名-->
       <el-row gutter="40">
        <el-col class="form-item" :span="8" :sm="24" :xs="24">
          <div class="label">{{$t('Name')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')" v-model.trim="userName"/>
          </div>
        </el-col>
        <!--Email-->
        <el-col class="form-item" :span="8" :sm="24" :xs="24">
          <div class="label">{{$t('Email')}}</div>
          <div>
            <input class="ipt" type="text" autocomplete="off" :placeholder="$t('PleaseEnter')"
                   v-model.trim="userEmail"/>
          </div>
        </el-col>
        <!--聯絡電話-->
        <el-col class="fl form-item" :span="8" :sm="24" :xs="24" >
          <div class="label">{{$t('Contact Number')}}({{$t('Optional')}})</div>
          <div class="relative ipt-group">
            <el-input size="small" :placeholder="$t('PleaseEnter')" type="number" autocomplete="off" v-model.trim="userPhone" class="input-with-select">
            <el-select  size="small" v-model="phonePrefix" slot="prepend" placeholder="请选择" :style="{width:'150px'}">
                <el-option v-for="item in areaCodeOptions"
                         :key="item.id"
                         :label="item.name"
                         :value="item.code">
                </el-option>
              </el-select>
            </el-input>
          </div>
        </el-col>
        </el-row>
      </div>

      <!--讯息-->
      <el-row type="flex" justify="center">
        <el-col class="form-item" :span="10">
          <div class="label">{{$t('message')}}</div>
          <div>
            <el-input type="textarea"
                      :placeholder="$t('PleaseEnter')"
                      show-word-limit
                      maxlength="1000"
                      v-model="message">
            </el-input>
          </div>
        </el-col>
      </el-row>
      <!--提交-->
      <div class="actions">
        <button class="button button-primary" @click="onSubmit">{{$t('Submit')}}</button>
      </div>
    </template>
    <div class="completed-wrap" v-else>
      <!--謝謝你的查詢，我們會盡快回覆！-->
      <div class="tit">{{$t('thx')}}</div>
      <div class="actions">
        <!--再次查詢-->
        <button class="button m-r" @click="submitCompleted=false">{{$t('QueryAgain')}}</button>
        <!--瀏覽精選的旅遊主題-->
        <button class="button button-primary" @click="$router.push({name:'ThemeTravel'})">
          {{$t('BrowseSelectedTravelThemes')}}
        </button>
      </div>
    </div>
    <div class="contact-info">
      <template v-if="$i18n.locale==='zh-cn'">
        <p>查询电话：(852) 2616 0676</p>
        <p>WhatsApp：(852) 6745 1568 ／ (852) 9847 9630</p>
        <p>传真：(852) 2120 3308</p>
        <p>电邮：info@unutravel.com</p>
        <p>地址：G/F 14-B San Kin Street, Sheung Shui,Hong Kong. <br/><span
          style="display:inline-block;text-indent:3em">香港上水新健街14号B地铺</span></p>
      </template>
      <template v-else-if="$i18n.locale==='en'">
        <p>Enquiry：(852) 2616 0676</p>
        <p>WhatsApp：(852) 6745 1568 ／ (852) 9847 9630</p>
        <p>Fax：(852) 2120 3308</p>
        <p>Email：info@unutravel.com</p>
        <p>Address：G/F 14-B San Kin Street, Sheung Shui,Hong Kong.</p>
      </template>
      <template v-else>
        <p>旅遊及產品對外whatsapp及電話: (852) 9609 5748 </p>
        <p>銷售部門: (852) 9340 3898，(852) 6745 1568</p>
        <p>傳真：(852) 2120 3308</p>
        <p>電郵：info@unutravel.com</p>
        <p>地址：G/F 14-B San Kin Street, Sheung Shui,Hong Kong. <br/><span
          style="display:inline-block;text-indent:3em">香港上水新健街14號B地舖</span></p>
      </template>
    </div>
  </div>
</template>
<script>
  import {addContact, getDictList} from '../../../api/common';
  import {isEmail} from '../../../utils/validate';

  export default {
    name: 'ContactUs',
    i18n: {
      messages: {
        'zh-cn': {
          thx: '谢谢你的查询，我们会尽快回覆！',
          QueryAgain: '再次查询',
          BrowseSelectedTravelThemes: '浏览精选的旅游主题'
        },
        'zh-hk': {
          thx: '謝謝你的查詢，我們會盡快回覆！',
          QueryAgain: '再次查詢',
          BrowseSelectedTravelThemes: '瀏覽精選的旅遊主題'
        },
        'en': {
          thx: '謝謝你的查詢，我們會盡快回覆！',
          QueryAgain: '再次查詢',
          BrowseSelectedTravelThemes: '瀏覽精選的旅遊主題'
        }
      }
    },
    data() {
      return {
        userName: '',
        userEmail: '',
        userPhone: '',
        message: '',
        submitCompleted: false,
        areaCodeOptions: [],
        phonePrefix: '852'
      };
    },

    created() {
      this.getDicts();
    },
    methods: {
      // 获取数据字典
      getDicts() {
        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value || [];
        });
      },
      onSubmit() {
        const data = {
          contactType: 'contact',
          userName: this.userName,
          userEmail: this.userEmail,
          phonePrefix: '',
          userPhone: '',
          message: this.message
        };

        if (this.userPhone) {
          data.phonePrefix = this.phonePrefix;
          data.userPhone = this.userPhone;
        }
        if (!data.userName) {
          this.$message.warning(this.$t('Please fill out', [this.$t('Name')]));
          return false;
        }
        if (!isEmail(data.userEmail)) {
          this.$message.warning(this.$t('PleaseEnterTheCorrectEmail'));
          return false;
        }
        // if (!data.userPhone) {
        //   this.$message.warning(this.$t('Please fill out', [this.$t('Contact Number')]));
        //   return false;
        // }
        if (!data.message) {
          this.$message.warning(this.$t('Please fill out', [this.$t('message')]));
          return false;
        }

        addContact(data).then(res => {
          this.userName = '';
          this.userEmail = '';
          this.userPhone = '';
          this.message = '';
          this.submitCompleted = true;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .contact-us{margin:0 auto;}
  .form-group{display:flex;flex-direction:row;justify-content:space-evenly;text-align:center;}
  .form-item{
    margin-top:24px;
    .label{padding-bottom:8px;color:#292c2d;font-size:16px;}
    .ipt{
      width:210px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .ipt-group{
      .ipt{padding-left:80px;}
    }
  }
  .actions{margin-top:32px;text-align:center;}
  .contact-info{
    margin-top:48px;padding:24px;background-color:#f4f4f4;
    p{
      margin-bottom:10px;line-height:1.8;color:#505050;font-size:16px;
      &:last-child{margin-bottom:0;}
    }
  }
  .completed-wrap{
    padding:40px 0;
    .tit{padding-bottom:20px;text-align:center;font-size:24px;}
  }
  .el-select .el-input {
    width: 130px;
  }
  .selectleft{
    padding-top: 2px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  .el-input-group{
    width: 320px;

  }
   @media only screen and (max-width: 600px) {
    .form-group {
      justify-content:center;
    display: flex;
    }
   }

</style>
